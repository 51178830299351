import React, { Suspense } from 'react';
import ReactDOMClient from 'react-dom/client';
import type { AppProps } from 'single-spa';
import singleSpaReact, { SingleSpaReactOpts } from 'single-spa-react';

import type { StudiosRevisionHistoryAppProps } from './components/StudiosRevisionHistoryApp';
import { isStandalone } from './utils/environment';

const StandaloneRoot = React.lazy(() => import('./components/StandaloneRoot'));

const RemoteRoot = React.lazy(() => import('./components/RemoteRoot'));

const ErrorBoundary = React.lazy(() => import('./components/ErrorBoundary'));

// Error boundaries should only be handled in standalone mode.
// We do not want a user being blocked if something critical happens this high within the
// component tree.
let errorBoundary: SingleSpaReactOpts<StudiosRevisionHistoryAppProps & AppProps>['errorBoundary'] | undefined;
let suppressComponentDidCatchWarning = true;

if (isStandalone()) {
  errorBoundary = (error, errorInfo, props) => (
    <Suspense fallback={<div />}>
      <ErrorBoundary error={error} errorInfo={errorInfo} appProps={props} />
    </Suspense>
  );
  suppressComponentDidCatchWarning = false;
}

const rootComponent = isStandalone() ? StandaloneRoot : RemoteRoot;

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent,
  renderType: 'createRoot',
  errorBoundary,
  suppressComponentDidCatchWarning,
});

export const { bootstrap, mount, unmount } = lifecycles;
